import React, { useState, useEffect } from 'react';
import './Gallery.css';

const Gallery = ({ files, onDelete }) => {
    const [selectedIndex, setSelectedIndex] = useState(null);

    const openPopup = (index) => {
        setSelectedIndex(index);
    };

    const closePopup = () => {
        setSelectedIndex(null);
    };

    const navigateGallery = (direction) => {
        setSelectedIndex((prevIndex) => {
            if (prevIndex === null) return null;
            const newIndex = prevIndex + direction;
            if (newIndex < 0) return files.length - 1;
            if (newIndex >= files.length) return 0;
            return newIndex;
        });
    };

    const handleKeyDown = (event) => {
        if (selectedIndex !== null) {
            if (event.key === 'ArrowLeft') navigateGallery(-1);
            if (event.key === 'ArrowRight') navigateGallery(1);
            if (event.key === 'Escape') closePopup();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedIndex]);

    const isImage = (file) => file.type.startsWith('image/');
    const isVideo = (file) => file.type.startsWith('video/');

    return (
        <div className="gallery">
            <div className="gallery-grid">
                {files.map((file, index) => (
                    <div key={index} className="gallery-item" onClick={() => openPopup(index)}>
                        {isImage(file) && <img src={URL.createObjectURL(file)} alt={file.name} />}
                        {isVideo(file) && <video src={URL.createObjectURL(file)} />}
                    </div>
                ))}
            </div>
            {selectedIndex !== null && (
                <div className="popup-overlay" onClick={closePopup}>
                    <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                        {isImage(files[selectedIndex]) && <img src={URL.createObjectURL(files[selectedIndex])} alt={files[selectedIndex].name} />}
                        {isVideo(files[selectedIndex]) && (
                            <video src={URL.createObjectURL(files[selectedIndex])} controls />
                        )}
                        <p>{files[selectedIndex].name}</p>
                        <div className="popup-buttons">
                            <button onClick={() => onDelete(selectedIndex)}>Delete</button>
                            <button onClick={closePopup}>Close</button>
                        </div>
                    </div>
                    <button className="nav-button prev" onClick={() => navigateGallery(-1)}>&lt;</button>
                    <button className="nav-button next" onClick={() => navigateGallery(1)}>&gt;</button>
                </div>
            )}
        </div>
    );
};

export default Gallery;
