import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import './FileUpload.css';
import Gallery from './Gallery'; // We'll create this component

const CHUNK_SIZE = 10 * 1024 * 1024; // 10MB chunks
const MAX_CONCURRENT_CHUNKS = 5;
const API_BASE_URL = 'https://upload.snapped.cc';

// Add this after imports
const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    }
});

const FileUpload = () => {
    const [files, setFiles] = useState([]);
    const [currentFileProgress, setCurrentFileProgress] = useState(0);
    const [uploadStatus, setUploadStatus] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedDestination, setSelectedDestination] = useState(null);
    const [filteredDestinations, setFilteredDestinations] = useState([]);
    const [currentFileName, setCurrentFileName] = useState('');
    const [sessionFolder, setSessionFolder] = useState('');
    const [destinationMessage, setDestinationMessage] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [uploadProgress, setUploadProgress] = useState({});
    const [showGallery, setShowGallery] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [destinations, setDestinations] = useState([]);

    const onDrop = useCallback((acceptedFiles) => {
        setFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
        onDrop, 
        multiple: true
    });

    useEffect(() => {
        // Fetch destinations when component mounts
        const fetchDestinations = async () => {
            try {
                const response = await axiosInstance.get('/api/destinations');
                console.log("Raw API response:", response.data);
                setDestinations(response.data);
                console.log('Loaded destinations:', response.data);
            } catch (error) {
                console.error('Error fetching destinations:', error);
                setErrorMessage('Failed to load destinations');
            }
        };
// FUCKKKKKK
        fetchDestinations();
    }, []);

    useEffect(() => {
        if (searchTerm.length < 4) {
            setFilteredDestinations([]);  // Clear results if less than 4 chars
            return;
        }
        
        const filtered = destinations.filter(dest => 
            dest.name.toLowerCase().startsWith(searchTerm.toLowerCase())
        );
        setFilteredDestinations(filtered);
    }, [searchTerm, destinations]);

    const handleSearchChange = (e) => {
        console.log('Search event fired:', e.target.value);
        const value = e.target.value;
        console.log('Search input changed to:', value);
        setSearchTerm(value);
        setSelectedDestination(null);
    };

    const debugSearch = () => {
        console.log('=== Current Search State ===');
        console.log('Search term:', searchTerm);
        console.log('All destinations:', destinations);
        console.log('Filtered destinations:', filteredDestinations);
        console.log('Selected destination:', selectedDestination);
        
        // Test filtering logic explicitly
        if (searchTerm.length >= 3) {
            const testFiltered = destinations.filter(dest => 
                dest.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            console.log('Test filtering results:', testFiltered);
        }
    };

    const handleDestinationSelect = (dest) => {
        console.log('Selected destination:', dest);
        setSelectedDestination(dest);
        setSearchTerm(dest.name);
        setDestinationMessage(`Selected destination: ${dest.name}`);
        setTimeout(() => setDestinationMessage(''), 3000);
    };

    const updateProgress = useCallback((fileName, chunkIndex, chunksCount, loaded, total) => {
        setUploadProgress(prev => {
            const prevProgress = prev[fileName] || 0;
            const chunkProgress = (loaded / total) * 100;
            const overallProgress = ((chunkIndex + chunkProgress / 100) / chunksCount) * 100;
            const smoothProgress = prevProgress * 0.7 + overallProgress * 0.3;
            return {
                ...prev,
                [fileName]: Math.min(smoothProgress, 99.9) // Cap at 99.9% until finalized
            };
        });
    }, []);

    const uploadChunk = async (chunk, chunkIndex, totalChunks, fileName, sessionFolder, retries = 3) => {
        const formData = new FormData();
        formData.append('file', chunk, fileName);
        formData.append('chunkIndex', chunkIndex.toString());
        formData.append('totalChunks', totalChunks.toString());
        formData.append('sessionFolder', sessionFolder);

        console.log('Uploading chunk:', {
            chunkIndex,
            totalChunks,
            fileName,
            sessionFolder,
            chunkSize: chunk.size
        });

        try {
            const response = await axiosInstance.post('/api/upload_chunk', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                timeout: 220000,
                onUploadProgress: (progressEvent) => {
                    updateProgress(fileName, chunkIndex, totalChunks, progressEvent.loaded, progressEvent.total);
                }
            });
            console.log('Chunk upload response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error uploading chunk:', error.response ? error.response.data : error.message);
            if (retries > 0) {
                console.log(`Retrying chunk ${chunkIndex}, ${retries} attempts left`);
                await new Promise(resolve => setTimeout(resolve, 2000));
                return uploadChunk(chunk, chunkIndex, totalChunks, fileName, sessionFolder, retries - 1);
            } else {
                throw error;
            }
        }
    };

    const removeCompletedUploads = useCallback(() => {
        setUploadProgress(prev => {
            const newProgress = { ...prev };
            Object.keys(newProgress).forEach(fileName => {
                if (newProgress[fileName] >= 100) {
                    delete newProgress[fileName];
                }
            });
            return newProgress;
        });
    }, []);

    const removeCompletedFile = (fileName) => {
        setFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
        setUploadProgress(prev => {
            const newProgress = { ...prev };
            delete newProgress[fileName];
            return newProgress;
        });
    };

    const uploadFile = useCallback(async (file, sessionFolder) => {
        if (!selectedDestination) {
            console.error('No destination selected');
            return { success: false, fileName: file.name, error: 'No destination selected' };
        }

        const chunks = Math.ceil(file.size / CHUNK_SIZE);
        
        setUploadProgress(prev => ({ ...prev, [file.name]: 0 }));

        for (let i = 0; i < chunks; i++) {
            const start = i * CHUNK_SIZE;
            const end = Math.min(start + CHUNK_SIZE, file.size);
            const chunk = file.slice(start, end);
            
            await uploadChunk(chunk, i, chunks, file.name, sessionFolder);
        }

        try {
            console.log('Finalizing upload with:', {
                sessionFolder,
                filename: file.name,
                expectedSize: file.size,
                destinationId: selectedDestination.name
            });

            await axiosInstance.post('/api/finalize_upload', {
                sessionFolder,
                filename: file.name,
                expectedSize: file.size,
                destinationId: selectedDestination.name
            });
            
            setUploadProgress(prev => ({ ...prev, [file.name]: 100 }));
            setTimeout(() => removeCompletedUploads(), 1000);
            removeCompletedFile(file.name);
            return { success: true, fileName: file.name };
        } catch (error) {
            console.error('Error finalizing upload:', error);
            return { success: false, fileName: file.name, error: error.message };
        }
    }, [removeCompletedUploads, selectedDestination]);

    const uploadFiles = async () => {
        if (files.length === 0) {
            setErrorMessage('Please select files to upload');
            return;
        }

        if (!selectedDestination) {
            setErrorMessage('Please select a destination');
            return;
        }

        setIsUploading(true);
        setUploadStatus('Initializing upload session...');
        setErrorMessage('');

        try {
            const sessionResponse = await axiosInstance.post('/api/start_upload_session', {
                client: 'web',
                content_type: 'multipart/form-data',
                fileName: files[0].name,
                destinationId: selectedDestination.name
            });

            const sessionFolder = sessionResponse.data.session_folder;
            setSessionFolder(sessionFolder);

            // Upload all files
            for (const file of files) {
                try {
                    await uploadFile(file, sessionFolder);
                    // Remove the file after successful upload
                    removeCompletedFile(file.name);
                } catch (error) {
                    console.error(`Error uploading ${file.name}:`, error);
                    setErrorMessage(`Failed to upload ${file.name}: ${error.message}`);
                }
            }

            // Only complete the session once, after all files are done
            const completeResponse = await axiosInstance.post('/api/complete_session', {
                sessionFolder: sessionFolder,
                destinationId: selectedDestination.name
            });

            if (completeResponse.data.emailSent) {
                setUploadStatus('All files uploaded and notification sent!');
            } else {
                setUploadStatus('Files uploaded but notification failed to send.');
            }

        } catch (error) {
            console.error('Upload error:', error);
            setErrorMessage(`Error uploading files: ${error.message}`);
        } finally {
            setIsUploading(false);
            setCurrentFileProgress(0);
            setCurrentFileName('');
        }
    };

    const toggleGallery = () => {
        setShowGallery(!showGallery);
    };

    const deleteFile = (index) => {
        setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    };

    const openFile = (file) => {
        setSelectedFile(file);
    };

    return (
        <div className="file-upload-container">
            <h2>Large File Upload (up to 10GB per file)</h2>
            <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p>Drop the files here ...</p>
                ) : (
                    <p>Drag 'n' drop some files here, or click to select files</p>
                )}
            </div>
            {files.length > 0 && (
                <div className="file-info">
                    <p>Selected files: {files.length}</p>
                    <div className="file-list-container">
                        <ul className="file-list">
                            {files.map((file, index) => (
                                <div key={index} className="file-item">
                                    <div className="file-item-content">
                                        <span>{file.name} - {(file.size / (1024 * 1024)).toFixed(2)} MB</span>
                                        <button onClick={() => deleteFile(index)} className="delete-icon" aria-label="Delete file">
                                            <i className="fas fa-times"></i>
                                        </button>
                                    </div>
                                    {uploadProgress[file.name] !== undefined && (
                                        <div className="file-progress">
                                            <progress value={uploadProgress[file.name]} max="100" />
                                            <span>{uploadProgress[file.name].toFixed(1)}%</span>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
            <div className="destination-search">
                <input 
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Type the first 4 letters of your name to search..."
                /> 
                <div className="destination-buttons">
                    {searchTerm.length >= 4 && filteredDestinations.map(dest => (
                        <button
                            key={dest.name}
                            onClick={() => handleDestinationSelect(dest)}
                            className="destination-button"
                        >
                            {dest.name}
                        </button>
                    ))}
                </div>
            </div>
            {destinationMessage && (
                <p className="destination-message">{destinationMessage}</p>
            )}
            <div className="button-container">
                <button onClick={toggleGallery} className="gallery-button">
                    {showGallery ? 'Close Gallery' : 'Open Gallery'}
                </button>
                <button onClick={uploadFiles} className="upload-button" disabled={isUploading}>
                    {isUploading ? 'Uploading...' : 'Upload Files'}
                </button>
            </div>
            {showGallery && (
                <Gallery 
                    files={files}
                    onDelete={deleteFile}
                    onOpen={openFile}
                />
            )}
            {selectedFile && (
                <div className="file-preview">
                    <h3>File Preview: {selectedFile.name}</h3>
                    {selectedFile.type.startsWith('image/') && (
                        <img src={URL.createObjectURL(selectedFile)} alt={selectedFile.name} />
                    )}
                    {selectedFile.type.startsWith('video/') && (
                        <video src={URL.createObjectURL(selectedFile)} controls />
                    )}
                    <button onClick={() => setSelectedFile(null)}>Close Preview</button>
                </div>
            )}
            {currentFileProgress > 0 && currentFileName && (
                <div>
                    <p>Uploading: {currentFileName}</p>
                    <progress value={currentFileProgress} max="100" />
                    <span>{Math.round(currentFileProgress)}%</span>
                </div>
            )}
            {uploadStatus && <p className="upload-status">{uploadStatus}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
    );
};

export default FileUpload;
